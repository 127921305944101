import {
  Button,
  Flex,
  CommonModalProps,
  Modal,
  ModalHead,
  ModalTitle,
  ModalCloseButton,
  Spacing,
  Txt,
} from '@florencecard/components';

import { colors } from '@florencecard-shared/colors';
import React, { ComponentProps, ReactNode } from 'react';

interface Props extends CommonModalProps<void> {
  title?: ReactNode;
  body?: ReactNode;
  showCloseButton?: boolean;
  button?: ReactNode | string;
  dialogProps?: Omit<ComponentProps<typeof Modal>, 'open' | 'onClose' | 'children'>;
}

export default function AlertDialog({
  open,
  onClose,
  title,
  body,
  showCloseButton = true,
  button = '확인',
  dialogProps,
}: Props) {
  const titleEl = typeof title === 'string' ? <ModalTitle>{title}</ModalTitle> : title;
  const bodyEl =
    typeof body === 'string' ? (
      <Txt
        as="p"
        fontSize={13}
        lineHeight="1.5"
        color={colors.grayBold}
        padding="0 16px"
        maxWidth="100%"
        wordBreak="break-all"
        overflowWrap="break-word"
        css={{
          textSizeAdjust: 'none',
        }}
      >
        {body}
      </Txt>
    ) : (
      body
    );
  const buttonEl =
    typeof button === 'string' ? (
      <Button size={44} onClick={() => onClose?.()}>
        <Txt fontSize={14} color={colors.primary}>
          {button}
        </Txt>
      </Button>
    ) : (
      button
    );

  return (
    <Modal open={open} onClose={onClose} width="80vw" maxWidth={280} {...dialogProps}>
      <div>
        {title != null || showCloseButton ? (
          <ModalHead>
            {title != null ? titleEl : null}
            {showCloseButton ? <ModalCloseButton /> : null}
          </ModalHead>
        ) : null}
        {body != null ? bodyEl : null}
        <Spacing size={24} />
        <Flex.CenterVertical justifyContent="flex-end" paddingLeft={8} paddingRight={8}>
          {buttonEl}
        </Flex.CenterVertical>
      </div>
    </Modal>
  );
}
