import { CommonModalProps, useModal } from '@florencecard/components';
import React, { ComponentProps, useCallback } from 'react';
import AlertDialog from '~/components/AlertDialog';

type Props = Omit<ComponentProps<typeof AlertDialog>, keyof CommonModalProps<void>>;

export function useAlertDialog() {
  const modal = useModal();

  return useCallback(
    (props: Props) => {
      return modal.open((close) => <AlertDialog onClose={close} {...props} />);
    },
    [modal],
  );
}
